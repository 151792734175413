var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseModal',{ref:"modal",attrs:{"title":"Schattingen","max-width":"tw-max-w-4xl"},on:{"hide":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"tw-mb-7.5 tw-p-6 tw-rounded-md tw-shadow-card tw-bg-white"},[_c('FormulateForm',{attrs:{"name":"createEstimateForm","title":"Schattingsrapport toevoegen","invalid-message":"Gelieve de verplichte velden correct in te vullen."},on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isLoading = ref.isLoading;
return [_c('FormulateInput',{attrs:{"type":"text","name":"title","label":"Titel schattingsrapport","value":_vm.defaultTitle,"validation":"required:trim","outer-class":"tw-m-0"}}),_c('FormulateInput',{attrs:{"type":"select","name":"reason","label":"Doel van de schatting","placeholder":"Selecteer een doel","options":_vm.reasonOptions}}),_c('div',{staticClass:"tw-my-4 tw-grid sm:tw-grid-cols-3 tw-gap-4"},[_c('FormulateInput',{attrs:{"type":"number","name":"price_per_square_meter_min","label":"Minimumprijs m²","placeholder":"euro / m²","validation":"bail|required|number|min:0","step":"1","min":"0","lang":"nl","input-class":['tw-rounded-tr-none tw-rounded-br-none'],"element-class":['tw-flex tw-items-center'],"outer-class":"tw-my-0"}},[_c('div',{staticClass:"input-unit",attrs:{"slot":"suffix"},slot:"suffix"},[_c('i',{staticClass:"fas fa-euro-sign"})])]),_c('FormulateInput',{attrs:{"type":"number","name":"price_per_square_meter_max","label":"Maximumprijs m²","placeholder":"euro / m²","validation":"bail|required|number|min:0","step":"1","min":"0","lang":"nl","input-class":['tw-rounded-tr-none tw-rounded-br-none'],"element-class":['tw-flex tw-items-center'],"outer-class":"tw-my-0"}},[_c('div',{staticClass:"input-unit",attrs:{"slot":"suffix"},slot:"suffix"},[_c('i',{staticClass:"fas fa-euro-sign"})])]),_c('FormulateInput',{attrs:{"type":"select","name":"language","label":"Taal","options":_vm.USER_LANG_OPTIONS,"validation":"required","outer-class":"tw-m-0"}})],1),_c('FormulateErrors',{staticClass:"tw-text-right"}),_c('div',{staticClass:"tw-flex tw-justify-end"},[_c('FormulateInput',{attrs:{"type":"submit","disabled":isLoading,"input-class":['tw-w-full sm:tw-w-auto'],"outer-class":"tw-mt-2 tw-w-full sm:tw-w-auto"}},[_c('i',{class:[
              'fas tw-mr-2',
              isLoading ? 'fa-spinner-third fa-spin' : 'fa-save'
            ]}),_vm._v(" Schattingsrapport toevoegen ")])],1)]}}]),model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1),(_vm.estimates.length)?_c('div',{staticClass:"tw-grid sm:tw-grid-cols-2 tw-gap-8"},_vm._l((_vm.estimates),function(estimate){return _c('div',{key:estimate.id,staticClass:"tw-p-4 tw-relative tw-flex tw-flex-col tw-justify-between tw-gap-2 tw-rounded-md tw-shadow-card tw-bg-white"},[_c('div',{staticClass:"tw-absolute tw-flex tw-flex-col tw-gap-3 tw-right-0 tw-top-0 tw--translate-y-2 tw-translate-x-1/2"},[_c('button',{staticClass:"action-button tw-bg-white tw-text-success tw-border-success hover:tw-bg-success",attrs:{"type":"button","title":"Verwijderen"}},[_c('i',{staticClass:"fas fa-pencil"})]),_c('button',{staticClass:"action-button tw-bg-white tw-text-danger tw-border-danger hover:tw-bg-danger",attrs:{"type":"button","title":"Verwijderen","disabled":estimate.deleting},on:{"click":function($event){return _vm.deleteEstimate(estimate)}}},[_c('i',{class:[
              'fas',
              estimate.deleting
                ? 'fa-spinner-third fa-spin'
                : 'fa-trash'
            ]})])]),_c(estimate.property_file ? 'a' : 'h4',{tag:"component",staticClass:"tw-my-0 tw-font-light tw-flex-grow",attrs:{"title":estimate.title,"download":estimate.title,"href":estimate.property_file}},[_vm._v(" "+_vm._s(estimate.title)+" ")]),_c('div',{staticClass:"tw-flex-col tw-gap-2"},[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-gap-2"},[_c('h5',{staticClass:"tw-my-0"},[_vm._v("Intrinsieke waarde:")]),_c('span',[_vm._v(_vm._s(_vm.currency(estimate.value_intrinsic_override || estimate.value_intrinsic)))])]),_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-gap-2"},[_c('h5',{staticClass:"tw-my-0"},[_vm._v("Advieswaarde:")]),_c('span',[_vm._v(_vm._s(_vm.currency(estimate.value_advice_override || estimate.value_advice)))])])]),(estimate.created_by)?_c('div',{staticClass:"tw-text-[11px] tw-italic tw-text-right"},[_vm._v(" Gemaakt door "+_vm._s(estimate.created_by.first_name)+" "+_vm._s(estimate.created_by.last_name)+" om "+_vm._s(_vm._f("date-day")(estimate.created_on))+" ")]):_vm._e()],1)}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }