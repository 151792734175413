<template>
  <div>
    <div class="tw-font-bold">
      De status van dit dossier werd gewijzigd naar 'Uit portefeuille'. De eindstatus van de transactie werd gewijzigd naar 'Beëindigd'.
    </div>
    <hr class="tw-my-4" />
    <h4>Leads</h4>
    <WizardLeadCount
      :property-id="propertyId"
      :show-break-leads-button='true'
      class="tw-mb-4"
    />
    <hr class="tw-my-4"/>
    <h4>Checklistitems</h4>
    <WizardChecklistItems :property-id="propertyId" />
    <hr class="tw-my-4"/>
    <h4>Kosten</h4>
    <template v-if="costs.length">
      <div>
        <ul v-for="cost in costs" :key="cost.id" class="tw-list-disc tw-ml-4">
          <li>{{ cost.product.product.name }} ({{ currency(cost.amount) }} excl. btw)</li>
        </ul>
        <router-link
          :to="{ name: 'PropertyInvoice', params: { id: propertyId } }"
          target="_blank"
          class="btn btn-sm btn-primary tw-mt-4"
        >
          Factuur opmaken
        </router-link>
      </div>
    </template>
    <span v-else>Er zijn geen openstaande kosten.</span>

    <hr class="tw-my-4" />
    <h4>Eigenaarsrapportering</h4>
    <router-link
      :to="{ name: 'PropertyCommunication', params: { id: propertyId }, query: { tab: 'owner-reports' } }"
      target="_blank"
      class="btn btn-sm btn-primary tw-mt-1"
    >
      Eigenaarsrapportering opmaken
    </router-link>
  </div>
</template>

<script>
import WizardLeadCount from '@/components/properties/WizardLeadCount'
import WizardChecklistItems from '@/components/properties/WizardChecklistItems'
import { currency } from '@/utils/helpers'
import { getCosts } from '@/services/costs'

export default {
  name: 'WizardRemovedFromPortfolioStep2',
  components: { WizardChecklistItems, WizardLeadCount },
  props: {
    property: {
      type: Object,
      required: true
    },
    propertyId: {
      type: [Number, String],
      required: true
    },
    isMandateEndDateInThePast: {
      type: Boolean,
      required: true
    }
  },
  created () {
    this.fetchCosts()
  },
  data () {
    return {
      costs: []
    }
  },
  methods: {
    currency,
    async fetchCosts () {
      const params = {
        is_invoiceable: true,
        is_settled: false,
        page_size: 100,
        property: this.propertyId
      }
      const response = await getCosts({ params })
      this.costs = response?.data?.results
      return response
    }
  }
}
</script>
