<template>
  <BaseModal
    ref="modal"
    title="Uit portefeuille"
    max-width="tw-max-w-2xl"
    @hide="onHide"
  >
    <WizardRemovedFromPortfolioStep1
      v-show="activeStep === 1"
      v-bind="{
        property,
        propertyId,
        terminationReasons,
      }"
      @finished="moveForward"
    />
    <WizardRemovedFromPortfolioStep2
      v-show="activeStep === 2"
      v-bind="{
        property,
        propertyId,
      }"
    />
  </BaseModal>
</template>

<script>
import WizardRemovedFromPortfolioStep1 from '@/components/properties/WizardRemovedFromPortfolioStep1'
import WizardRemovedFromPortfolioStep2 from '@/components/properties/WizardRemovedFromPortfolioStep2'
import { getReasons } from '@/services/apiService'

export default {
  name: 'WizardRemovedFromPortfolio',
  components: {
    WizardRemovedFromPortfolioStep1,
    WizardRemovedFromPortfolioStep2
  },
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  computed: {
    propertyId () {
      return this.property.id
    }
  },
  data () {
    return {
      activeStep: 1,
      terminationReasons: {}
    }
  },
  methods: {
    onHide () {
      // Trigger a full page refresh when user closes wizard after submitting step 1
      if (this.activeStep === 2) this.$emit('wizardClosed')
    },
    goBack (step) {
      this.activeStep = step - 1
    },
    moveForward () {
      this.activeStep = this.activeStep + 1
      this.$emit('submitted')
    },
    async show () {
      const initialValuesResponse = await this.fetchInitialValues()
      this.$refs.modal.show()
      return initialValuesResponse
    },
    async fetchInitialValues () {
      const params = { type__key: 'termination_reason' }
      const response = await getReasons({ params })
      this.terminationReasons = response.data?.results.map(reason => { return { label: reason.name, value: reason.id } })
      return response
    }
  }
}
</script>
